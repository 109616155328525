<template>
  <svg
    id="flag_2"
    viewBox="0 0 62 62"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="31" cy="31" r="31" fill="#1F804D" />
    <path d="M36.7058 25.7656V21.6362H32.4705V25.7656H36.7058Z" fill="white" />
    <path d="M28.2352 25.7656V21.6362H24V25.7656H28.2352Z" fill="white" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 12.52V50.48C16 50.7672 16.2388 51 16.5333 51H19.4667C19.7612 51 20 50.7672 20 50.48V37.35H46C46.5304 37.35 47.0391 37.1446 47.4142 36.7789C47.7893 36.4132 48 35.9172 48 35.4V15.9C48 15.3828 47.7893 14.8868 47.4142 14.5211C47.0391 14.1554 46.5304 13.95 46 13.95H20V12.52C20 12.2328 19.7612 12 19.4667 12H16.5333C16.2388 12 16 12.2328 16 12.52ZM40.9411 21.6353V17.5059H45.1764V21.6353H40.9411ZM36.7058 25.7656H40.9411V29.8942H45.1764V25.7648H40.9411V21.6353H36.7058V17.5059H32.4705V21.6353H28.2352V17.5059H23.9999V21.6353H19.7646V25.7648H23.9999V29.8942H19.7646V34.0236H23.9999V29.8942H28.2352V34.0236H32.4705V29.895H28.2352V25.7656H32.4705V29.8942H36.7058V34.0236H40.9411V29.895H36.7058V25.7656Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconFlag2',
}
</script>
