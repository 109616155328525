<template>
  <section class="page-error">
    <div class="container text-center">
      <div class="flex justify-center">
        <ErrorImage class="w-[200px]" />
      </div>

      <h1 class="error__title">
        {{ $t('title') }}
      </h1>

      <p class="error__body">
        {{ $t('text') }}
      </p>

      <div class="error__buttons">
        <TBtnLink class="t-btn--primary" to="/">
          {{ $t('back-to-homepage') }}
        </TBtnLink>
      </div>
    </div>
  </section>
</template>

<script>
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import ErrorImage from 'chimera/all/themes/mars/images/ErrorImage'

export default {
  name: 'ErrorPage',

  components: {
    ErrorImage,
  },

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: undefined,
      path: '/error',
    }
  },

  /**
   */
  created() {
    this.headTitle = this.$t('title')
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Sorry! Er is iets niet helemaal goed gegaan.",
    "text": "Klik op de knop hieronder, dan ga je terug naar de homepage van onze website.",
    "back-to-homepage": "Terug naar de homepage"
  }
}
</i18n>
