<template>
  <svg fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m17 2h-10c-1.1046 0-2 0.89543-2 2v16c0 1.1046 0.89543 2 2 2h10c1.1046 0 2-0.8954 2-2v-16c0-1.1046-0.8954-2-2-2z"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
    <path
      d="m12 18h0.01"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconPhone',
}
</script>
