<template>
  <div class="form-modal">
    <div class="form-modal__top">
      <nuxt-link to="/" class="form-modal__logo">
        <InlineSvg class="h-6" src="images/logo.svg" />
      </nuxt-link>

      <nuxt-link to="/" class="form-modal__close">
        <IconClose class="w-5 h-5" />
      </nuxt-link>
    </div>

    <!--    <div class="form-modal__progress">-->
    <!--      <slot name="progressbar" />-->
    <!--    </div>-->

    <slot name="title">
      <div v-if="title">
        <h1 class="form-modal__title">
          {{ title }}
        </h1>
      </div>
    </slot>

    <div class="form-modal__wrapper">
      <div class="form-modal__body">
        <slot name="body" />
      </div>
    </div>

    <div class="form-modal__footer">
      <slot name="footer">
        <div class="footer__container">
          <t-btn
            v-if="showBackBtn"
            id="footerBackButton"
            class="t-btn--secondary"
            @click="$router.back()"
          >
            {{ $t('global.back') }}
          </t-btn>
          <t-btn
            id="footerNextButton"
            class="t-btn--primary"
            @click="$nuxt.$emit('submit')"
          >
            {{ nextBtnText || $t('global.continue') }}
          </t-btn>
        </div>
      </slot>
    </div>
  </div>
</template>

<script>
import InlineSvg from 'vue-inline-svg'
import IconClose from 'chimera/all/themes/jupiter/images/icons/IconClose'

export default {
  name: 'FormModal',

  components: {
    IconClose,
    InlineSvg,
  },

  props: {
    title: {
      type: String,
      default: '',
    },

    description: {
      type: String,
      default: '',
    },

    showBackBtn: {
      type: Boolean,
      default: false,
    },

    nextBtnText: {
      type: String,
      default: '',
    },
  },
}
</script>
