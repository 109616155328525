<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m15.589 4.4111c0.3254 0.32544 0.3254 0.85307 0 1.1785l-10 10c-0.32543 0.3254-0.85307 0.3254-1.1785 0-0.32544-0.3255-0.32544-0.8531 0-1.1785l10-10c0.3254-0.32544 0.8531-0.32544 1.1785 0z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="m4.4106 4.4111c0.32544-0.32544 0.85308-0.32544 1.1785 0l10 10c0.3254 0.3254 0.3254 0.853 0 1.1785-0.3254 0.3254-0.8531 0.3254-1.1785 0l-10-10c-0.32544-0.32544-0.32544-0.85307 0-1.1785z"
      clip-rule="evenodd"
      fill="currentColor"
      fill-rule="evenodd"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconClose',
}
</script>
