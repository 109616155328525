<template>
  <div class="page-thank-you">
    <div class="container">
      <div class="flex justify-center mb-8">
        <div class="w-16 h-16 relative">
          <IconFlag1 class="absolute animate-fade-loop animate-delay-1600" />
          <IconFlag2 class="absolute animate-fade-loop animate-delay-800" />
        </div>
      </div>

      <i18n tag="h1" path="title" class="thank-you__title">
        <template #break>
          <br />
        </template>
      </i18n>

      <h2 class="thank-you__subtitle">
        {{ $t('subtitle') }}
      </h2>

      <i18n tag="p" path="text" class="thank-you__text">
        <template #break>
          <br />
        </template>
      </i18n>
    </div>

    <div class="tips container">
      <ul class="tips__container">
        <h2 class="thank-you__subtitle">
          {{ $t('tips-title') }}
        </h2>

        <div class="mt-10 w-full flex flex-col md:flex-row gap-x-6 gap-y-9">
          <div v-for="tip of tips" :key="tip.text" class="flex md:w-1/3">
            <div>
              <div
                class="flex items-center justify-center h-10 w-10 rounded-full bg-secondary text-white"
              >
                <component :is="tip.icon" class="h-6 w-6" />
              </div>
            </div>
            <div>
              <i18n tag="p" :path="tip.text" class="leading-5 text-left ml-3">
                <template v-if="tip.emphasis" #emphasis>
                  <em class="font-semibold">
                    {{ $t(tip.emphasis) }}
                  </em>
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import Usps from 'chimera/all/themes/jupiter/components/Usps'
import AbstractPage from 'chimera/all/components/page/AbstractPage'
import IconPhone from 'chimera/all/themes/jupiter/images/icons/IconPhone'
import IconMagnifingGlass from 'chimera/all/themes/jupiter/images/icons/IconMagnifingGlass'
import IconCheckCircle from 'chimera/all/themes/jupiter/images/icons/IconCheckCircle'
import IconFlag1 from 'chimera/all/themes/mars/images/icons/IconFlag1'
import IconFlag2 from 'chimera/all/themes/mars/images/icons/IconFlag2'

export default {
  components: {
    Usps,
    IconFlag1,
    IconFlag2,
  },

  extends: AbstractPage,

  layout: 'content',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Aanvraag afgerond',
      headDescription:
        'Vul je postcode in en vergelijk geheel gratis 6 offertes van top Autorijscholen uit jouw regio. ✓Dé beste prijs voor jouw rijschool ✓Bespaar tot 40% ✓100% Gratis!',
      path: '/offertes-aanvragen/aanvraag-afgerond',
      flags: [
        {
          component: IconFlag1,
          id: 'flag_1',
        },
        {
          component: IconFlag2,
          id: 'flag_2',
        },
      ],
      tips: [
        {
          icon: IconPhone,
          text: 'tips1-text',
        },
        {
          icon: IconMagnifingGlass,
          text: 'tips2-text',
        },
        {
          icon: IconCheckCircle,
          text: 'tips3-text',
          emphasis: 'tips3-text-emphasis',
        },
      ],
    }
  },
}
</script>

<i18n>
{
  "nl-NL": {
    "title": "Dank je!{break} Jouw aanvraag is ingediend.",
    "subtitle": "Wat gebeurt er nu?",
    "text": "Wij hebben alvast de beste rijscholen in jouw regio gevonden.{break} Zij zullen spoedig contact met je opnemen om hun offertes door te geven.",
    "tips-title": "Hoe maak je er het beste van?",
    "tips1-text": "Zorg dat je je telefoon bij de hand hebt, want professionals kunnen proberen je telefonisch te bereiken.",
    "tips2-text": "Kijk niet alleen naar de prijs, maar vergelijk ook op kwaliteit, service en betrouwbaarheid.",
    "tips3-text": "Neem de tijd om de juiste professional te kiezen. {emphasis} de offertes zijn gratis en je bent niet verplicht een partner te kiezen.",
    "tips3-text-emphasis": "Let op:"
  }
}
</i18n>
