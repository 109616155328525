<template>
  <div>
    <div class="form-row border-b-0">
      <NameField :label="'Jouw naam *'" placeholder="" v-on="$listeners" />
    </div>

    <div class="form-row border-t-0">
      <PhoneField
        :label="'Jouw telefoonnummer *'"
        placeholder=""
        v-on="$listeners"
      />
    </div>

    <div class="form-row">
      <EmailField :label="'Jouw e-mail *'" placeholder="" v-on="$listeners" />
    </div>

    <div class="form-row">
      <CommentsField
        :label="'Overige informatie'"
        :is-optional="true"
        placeholder=""
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<script>
import NameField from 'chimera/all/components/form/fields/name/NameField'
import EmailField from 'chimera/all/components/form/fields/email/EmailField'
import CommentsField from 'chimera/all/components/form/fields/comments/CommentsField'
import PhoneField from 'chimera/all/components/form/fields/phone/PhoneField'

export default {
  name: 'ContactInformationFormPart',

  components: {
    NameField,
    EmailField,
    CommentsField,
    PhoneField,
  },
}
</script>
