<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.66668 10.8333C2.66668 6.323 6.32302 2.66666 10.8333 2.66666C15.3437 2.66666 19 6.323 19 10.8333C19 13.0336 18.1299 15.0306 16.715 16.4991C16.675 16.5298 16.6366 16.5634 16.6 16.6C16.5635 16.6366 16.5298 16.675 16.4991 16.715C15.0306 18.1299 13.0336 19 10.8333 19C6.32302 19 2.66668 15.3437 2.66668 10.8333ZM17.3873 19.0372C15.591 20.4741 13.3125 21.3333 10.8333 21.3333C5.03435 21.3333 0.333344 16.6323 0.333344 10.8333C0.333344 5.03434 5.03435 0.333328 10.8333 0.333328C16.6323 0.333328 21.3333 5.03434 21.3333 10.8333C21.3333 13.3125 20.4741 15.591 19.0372 17.3873L23.325 21.675C23.7806 22.1306 23.7806 22.8693 23.325 23.3249C22.8694 23.7806 22.1307 23.7806 21.675 23.3249L17.3873 19.0372Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6442 6.6535C11.3985 6.1558 10.6888 6.1558 10.4431 6.6535L9.47485 8.61515C9.37738 8.81261 9.18907 8.94954 8.97118 8.98139L6.80474 9.29805C6.25564 9.37831 6.0368 10.0533 6.43433 10.4405L8.0009 11.9663C8.15886 12.1202 8.23097 12.3419 8.19369 12.5593L7.82412 14.7141C7.73028 15.2612 8.30458 15.6784 8.7959 15.42L10.732 14.4019C10.9271 14.2992 11.1602 14.2992 11.3554 14.4019L13.2914 15.42C13.7828 15.6784 14.3571 15.2612 14.2632 14.7141L13.8936 12.5593C13.8564 12.3419 13.9285 12.1202 14.0864 11.9663L15.653 10.4405C16.0505 10.0533 15.8317 9.37831 15.2826 9.29805L13.1162 8.98139C12.8983 8.94954 12.71 8.81261 12.6125 8.61515L11.6442 6.6535Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMagnifyingGlass',
}
</script>
